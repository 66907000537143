<template>
  <v-row no-gutters>
    <v-col lg="5" cols="12" class="py-4 px-6">
      <h3>Demande d'aide</h3>
      <v-form>
        <v-text-field label="Objet" v-model="help.objet"></v-text-field>
        <v-select :items="type_select" item-value="value" item-text="name" label="Type de demande"
                  v-model="help.type"></v-select>
        <v-textarea label="Description" v-model="help.description"></v-textarea>
        <v-btn color="primary" @click="createHelp">Envoyer la demande</v-btn>
      </v-form>
    </v-col>

    <v-col v-if="loading_requests" lg="7" cols="12" class="py-4 px-6 overflow-y-auto"
           style="max-height: calc(90vh - 130px)">
      <h3>Demandes</h3>
      <v-card class="mt-2" v-for="request in requests" :key="request.id">
        <v-card-title>{{request.objet}}</v-card-title>
        <v-card-subtitle v-if="request.created_by !== null" class="pb-0 mb-0">
          Par :  {{ request.created_by.first_name}} {{ request.created_by.last_name}}
        </v-card-subtitle>
        <v-card-subtitle v-else class="pb-0 mb-0">
          Par :  Utilisateur supprimé
        </v-card-subtitle>

        <v-card-subtitle class="pt-0 mt-0">Le : {{ formatDate(request.date_creation) }}</v-card-subtitle>
        <v-card-text>{{ request.description }}</v-card-text>
        <v-card-actions>
          <v-btn color="red" v-if="isResponsableOrAdmin" class="white--text" @click="deleteHelp(request)">Supprimer</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col v-else lg="7" cols="12" class="py-4 px-6 overflow-y-auto" style="max-height: calc(90vh - 130px)">
      <div class="align-center justify-center">
        <v-progress-circular></v-progress-circular>
      </div>
    </v-col>
  </v-row>
</template>
<script>

import {AuthMixin} from "@/mixins/util/AuthMixin";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";

const type_select = [
  {name: "Erreur", value: "erreur"},
  {name: "Suggestion", value: "suggestion"},
  {name: "Support", value: "support"},
]

const emptyHelp = {
  objet: "",
  type: "erreur",
  description: "",
  created_by: null,
}
export default {
  name: 'Support',
  mixins: [AuthMixin, DateUtilMixin],
  async mounted() {
    if (this.$store.getters["help/getRequests"].length === 0) {
      await this.$store.dispatch("help/fetchRequests");
    }
    this.loading_requests = true;
  },
  data() {
    return {
      type_select,
      help: {...emptyHelp},
      loading_requests: false,
    }
  },
  computed: {
    requests() {
      return this.$store.getters["help/getRequests"];
    }
  },
  methods: {
    async createHelp() {
      this.help.created_by = this.logged_user.id;
      await this.$store.dispatch("help/postRequest", this.help);
      await this.$store.dispatch("messages/announceSuccess", "Votre demande a été envoyé.");
      this.help = {...emptyHelp, created_by: this.logged_user.id}
    },
    async deleteHelp(help) {
      await this.$store.dispatch("help/deleteRequest", help);
      await this.$store.dispatch("messages/announceSuccess", "Cette demande a été supprimé.");
    }
  }
}
</script>
<style>
.limitation-border {
  border: #208774 1px solid;
  border-radius: 5px;
}
</style>
