<template>
  <div class="pl-4 pt-4 mb-4" style="height: 200px">

          <v-simple-table>
            <template>
              <thead>
              <tr>
                <th>Nom du document</th>
                <th>Lien</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Notice Application Mobile Optia</td>
                <td><a class="text-decoration-none" target="_blank" href="/pdf/NoticeMobileOptia.pdf"><v-icon color="primary">mdi-file</v-icon></a></td>
              </tr>
              <tr v-if="isResponsableOrAdmin">
                <td>Notice Responsable Technique Application Web Optia</td>
                <td><a class="text-decoration-none" target="_blank" href="/pdf/NoticeWebRPOptia.pdf"><v-icon color="primary">mdi-file</v-icon></a></td>
              </tr>
              <tr>
                <td>Notice Collaborateur Application Web Optia</td>
                <td><a class="text-decoration-none" target="_blank" href="/pdf/NoticeWebCollabOptia.pdf"><v-icon color="primary">mdi-file</v-icon></a></td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
  </div>
</template>
<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";

export default {
  name: 'Documentation',
  mixins: [AuthMixin],
}
</script>