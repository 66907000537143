<template>
<v-container>
  <v-tabs v-model="tabs">
    <v-tab>Support</v-tab>
    <v-tab>Guides d'utilisation</v-tab>
  </v-tabs>
  <v-tabs-items v-model="tabs">
    <v-tab-item>
      <Support/>
    </v-tab-item>
    <v-tab-item>
      <Documentation/>
    </v-tab-item>
  </v-tabs-items>
</v-container>
</template>

<script>
import Support from "@/views/Support";
import Documentation from "@/views/Documentation";

export default {
  components: {Documentation, Support},
  async mounted() {

  },
  data() {
    return {
      tabs: 0,
    }
  }
}
</script>

